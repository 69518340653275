'use client';

import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

// import { toClassName } from 'helpers';

import { handleUseStrapiImageUrl } from 'helpers/strapi';

import { TNextServerComponent } from 'types/next';

import { styles } from '.';

type TProps = {
  index: number;
  location: {
    km: number;
    id: string;
    name: { [key: string]: string };
    slug: string;
    isArrowSwapped?: boolean;
    primaryImage: any;
  };
  lang: string;
  setActiveIndex: any;
};

const HomeLandingListItem: TNextServerComponent<TProps> = (props) => {
  const { index, lang, location, setActiveIndex } = props;

  return (
    <li>
      <Link
        href={`/${lang}/${location.slug}`}
        onMouseEnter={() => setActiveIndex(index)}
        onMouseLeave={() => setActiveIndex(null)}
      >
        <div className={styles.container}>
          <Image
            src={handleUseStrapiImageUrl(
              `/images/texture-${location.slug}.png`,
            )}
            fill
            alt="texture"
            className="object-cover"
          />
          <h2 className={styles.heading}>{location.name[lang]}</h2>
          <p className={styles.paragraph}>{location.km}</p>
          {/* <svg
            preserveAspectRatio="none"
            viewBox="0 0 512 512"
            width={120}
            height={100}
            className={toClassName(
              styles.image.svg,
              location.isArrowSwapped ? 'rotate-180' : '',
            )}
          >
            <path
              className={styles.image.path}
              d="M0 256L160 96l32 0 0 96 320 0 0 128-320 0 0 96-32 0L0 256z"
            />
          </svg> */}
        </div>
      </Link>
    </li>
  );
};

export default HomeLandingListItem;
