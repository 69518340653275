'use client';

import { useState } from 'react';

import Image from 'next/image';

import { toClassName } from 'helpers';

import { handleUseStrapiImageUrl } from 'helpers/strapi';

import HomeLandingListItem from '../components/ListItem/HomeLandingListItem';
import styles from '../Landing/HomeLandingPage.styles';

type TProps = {
  lang: string;
  //
  general: any;
  locations: any;
};

const HomeClientView = (props: TProps) => {
  const { lang, general, locations } = props;

  const [activeIndex, setActiveIndex] = useState(null);

  return (
    <>
      <div className={styles.head.container}>
        <Image
          src="/images/logo-big.svg"
          width={400}
          height={100}
          alt="logo-big"
          className="w-full"
          priority
        />
      </div>
      <div className={styles.body.container}>
        <div className={styles.body.figure}>
          <Image
            src={handleUseStrapiImageUrl(
              general?.attributes?.welcomeImage?.data?.attributes?.url,
            )}
            fill
            alt="landing"
            className={toClassName(
              styles.body.image,
              activeIndex !== null && 'opacity-0',
            )}
          />
          <Image
            src={handleUseStrapiImageUrl(
              locations[0].primaryImage.data.attributes.url,
            )}
            fill
            alt="landing"
            className={toClassName(
              styles.body.image,
              activeIndex === 0 ? 'opacity-100' : 'opacity-0',
            )}
          />
          <Image
            src={handleUseStrapiImageUrl(
              locations[1].primaryImage.data.attributes.url,
            )}
            fill
            alt="landing"
            className={toClassName(
              styles.body.image,
              activeIndex === 1 ? 'opacity-100' : 'opacity-0',
            )}
          />
          <Image
            src={handleUseStrapiImageUrl(
              locations[2].primaryImage.data.attributes.url,
            )}
            fill
            alt="landing"
            className={toClassName(
              styles.body.image,
              activeIndex === 2 ? 'opacity-100' : 'opacity-0',
            )}
          />
          <Image
            src={handleUseStrapiImageUrl(
              locations[3].primaryImage.data.attributes.url,
            )}
            fill
            alt="landing"
            className={toClassName(
              styles.body.image,
              activeIndex === 3 ? 'opacity-100' : 'opacity-0',
            )}
          />
        </div>
      </div>
      <div className={styles.foot.container}>
        {/* <div className="hidden md:block animate-point absolute left-0 -ml-36 mt-8">
          <Image
            src="/images/glyph-1.png"
            width={100}
            height={100}
            alt="glyph"
            className="-scale-x-100"
          />
        </div> */}
        <ul className={styles.foot.list}>
          {locations
            ?.map((location: any, index: number) => ({
              ...location,
              km:
                index === 0
                  ? 'St. Catherine'
                  : index === 1
                    ? 'Vlasmarkt'
                    : index === 2
                      ? 'Eiermarkt'
                      : index === 3
                        ? 'Langestraat'
                        : 0,
              isArrowSwapped: index === 0 ? true : false,
            }))
            .map((location: any, index: number) => (
              <HomeLandingListItem
                key={location.id}
                index={index}
                location={location}
                lang={lang}
                setActiveIndex={setActiveIndex}
              />
            ))}
        </ul>
        {/* <div className="hidden md:block animate-point-reverse absolute right-0 -mr-36 -mt-20">
          <Image
            src="/images/glyph-1.png"
            width={100}
            height={100}
            alt="glyph"
            className=""
          />
        </div> */}
      </div>
    </>
  );
};

export default HomeClientView;
