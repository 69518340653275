const styles = {
  container:
    'relative group text-white rounded-md ring-white ring-4 flex flex-col items-center py-4 hover:text-primary-500 cursor-pointer hover:ring-primary-500',
  heading: 'font-display uppercase font-semibold text-5xl',
  paragraph: 'font-display uppercase font-semibold text-2xl',
  image: {
    svg: 'h-8 mt-1',
    path: 'fill-white group-hover:fill-primary-500',
  },
};

export default styles;
