const styles = {
  container: 'grow flex flex-col mb-[2.5rem]',
  content: 'grow flex flex-col gap-y-5',

  head: {
    container: '',
  },

  body: {
    container: 'h-[30rem] hidden sm:flex flex-col',

    figure: 'grow rounded-xl overflow-hidden relative bg-gray-900',
    image: 'object-cover duration-500 ease-in',
  },

  foot: {
    container: 'relative',

    list: 'flex flex-col gap-y-4 sm:grid grid-cols-2 lg:grid-cols-4 gap-x-6',
  },
};

export default styles;
